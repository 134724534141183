import { Route, Routes } from 'react-router';
import './App.css'; import NavBar from './Section/NavBar/NavBar';
import Footer from './Section/Footer/Footer';
import Hero from './Section/Hero/Hero';
// import Shops from './Section/Shop/Shops';
import About from './Section/About/About';
import Blog from './Section/Blog/Blog';
import Products from './Section/products/Products';
import Items from './Section/Shop/Items';
import Voltbar from './Section/Shop/Voltbar';
import VoltbarCartr from './Section/Shop/VoltbarCartr';
import Akso from './Section/Shop/Akso';
import Login from './Section/Login/Login';
import SignUp from './Section/Login/SignUp';
import Admin from './Section/DashBoard/Admin';
import Dashboard from './Section/DashBoard/DashBoard';

function App() {
  return (
    <div>
      <NavBar></NavBar>
      <Routes>
        <Route path='/' element={<Hero></Hero>}></Route>
        <Route path='/home' element={<Hero></Hero>}></Route>
        <Route path='/products' element={<Products></Products>}></Route>
        {/* <Route path='/Shop' element={<Shops></Shops>}></Route> */}
        <Route path='/about' element={<About></About>}></Route>
        <Route path='/blogs' element={<Blog></Blog>}></Route>
        <Route path='/items' element={<Items></Items>}></Route>
        <Route path='/voltbar' element={<Voltbar></Voltbar>}></Route>
        <Route path='/voltbarCartridge' element={<VoltbarCartr></VoltbarCartr>}></Route>
        <Route path='/akso' element={<Akso></Akso>}></Route>
        <Route path='/Login' element={<Login></Login>}></Route>
        <Route path='/signup' element={<SignUp></SignUp>}></Route>


        <Route path='/dashboard' element={<Dashboard></Dashboard>}></Route>

        <Route path='/admin' element={<Admin></Admin>}></Route>

      </Routes>
      <Footer></Footer>

    </div>
  );
}

export default App;
