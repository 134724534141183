import React from 'react';
import vaping from '../../ImgFile/vapeVScig.jpg'
import { Link } from 'react-router-dom';
const Blog = () => {
    return (
        <div>
            <h1 className='bg-gray-500 text-4xl font-bold text-center p-20'>BLogs</h1>
            <div>
                <div className="hero min-h-screen bg-base-200">
                    <div className="hero-content flex-col lg:flex-row">
                        <img className='w-96' src={vaping} alt='..' />
                        <div>
                            <h1 className="text-5xl font-bold">Benefits</h1>
                            <p className="py-6">Here are some potential benefits or positive aspects associated with vaping</p>
                            <li className='btn btn-primary'><Link to='blog1'>Read</Link></li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;