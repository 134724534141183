import React from 'react';

const Admin = () => {
    const handleAddUser = event => {
        event.preventDefault();
        const form = event.target;
        const name = form.name.value;
        const email = form.email.value;
        const user = { name, email };
        console.log(user)
        fetch('http://localhost:5000/users', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(user)

        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.insertedId) {
                    alert('Users added Successfully')
                    form.reset();
                }
            });

    }
    return (
        <div className='p-32'>
            <form onSubmit={handleAddUser}>
                <input type='text' name='name' id=''>
                </input>
                <input type='text' name='email' id=''>
                </input>
                <input type='submit' value='Add user' id=''>
                </input>
            </form>
        </div>
    );
};

export default Admin;