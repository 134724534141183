import React from 'react';
// import aboutBanner from '../../ImgFile/shop-vape-poster-1.jpg';
import aboutBanner2 from '../../ImgFile/aboutbanner2.jpg';


const About = () => {
    return (
        <div>
            <h1 className='bg-gray-500 text-4xl font-bold text-center p-20'>About US</h1>
            <div>
                <h1 className='text-4xl font-bold text-center p-20'>We're the most popular in Bangladesh and don't hesitate to knock us.</h1>
            </div>
            <div className='grid place-items-center'>
                {/* <img className='rounded-lg' src={aboutBanner} alt=''></img> */}
                <img className='rounded-lg' src={aboutBanner2} alt="Album" />
            </div>
            {/* <div className="diff aspect-[16/9]">
                <div className="diff-item-1">
                    <div className="bg-primary text-primary-content text-9xl font-black grid place-content-center">HOUSE OF</div>
                </div>
                <div className="diff-item-2">
                    <div className="bg-base-200 text-9xl font-black grid place-content-center">ClOUDS</div>
                </div>
                <div className="diff-resizer"></div>
            </div> */}
        </div>
    );
};

export default About;