import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import heroPic from '../../ImgFile/VOLTBAR-SWITCH-WEBSITE-BANNER.jpg';
import Home from './Home';
import firstOne from '../../ImgFile/cover-2.jpg';
import firstOne1 from '../../ImgFile/voltbarCover-1.jpg';


const Hero = () => {
    return (
        <div>
            <div>
                <Carousel>
                    <div>
                        <img src={firstOne} alt='vape' />
                    </div>
                    <div>
                        <img src={firstOne1} alt='vape' />
                    </div>
                    <div>
                        <img src={firstOne} alt='vape' />
                    </div>
                </Carousel>

            </div>
            <div>
                <img src={heroPic} alt='vape'></img>
            </div>
            <Home></Home>
        </div>
    );
};

export default Hero;