import React from 'react';
import { Link } from 'react-router-dom';





const Shops = () => {
    return (
        <div>
            <div>
                <div className="text-sm breadcrumbs">
                    <ul className='justify-center pt-20'>
                        <li><Link to='/items'>All Items</Link></li>
                        <li><Link to='/voltbar'>Voltbar</Link></li>
                        <li><Link to='/voltbarCartridge'>Voltbar Cartridge</Link></li>
                        <li><Link to='/akso'>Akso supa</Link></li>
                    </ul>

                    
                </div>
            </div>

        </div>
    );
};

export default Shops;