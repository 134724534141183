import React, { useState } from 'react';
import ItemsCard from './ItemsCard';
import UseItems from './UseItems';
import Shops from './Shops';

// import products1 from '../../ImgFile/products-1.jpg';


const Items = () => {
    const Items = UseItems()
    const [items, setItems] = useState(Items)


    return (

        <div>
            <Shops></Shops>
            <div className='lg:grid-cols-3 md:grid-cols-2 sm:grid grid-cols-1 m-4 p-4'>
                {
                    Items.map(item => <ItemsCard

                        key={item._id}
                        item={items}
                        items={items}
                        setItems={setItems}

                    ></ItemsCard>)

                }

            </div>
        </div>

    );
};

export default Items;