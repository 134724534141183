import '../hove.css';
import VapCig from '../../ImgFile/vapicon.jpg';
import heroPic1 from '../../ImgFile/hero-1.jpg';
import heroPic2 from '../../ImgFile/hero-2.jpg';
import heroPic3 from '../../ImgFile/products-10.jpg';
import heroPic4 from '../../ImgFile/Cartige-semi-Cover.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


import caru1 from '../../ImgFile/products-1.jpg';
import caru3 from '../../ImgFile/NITRO.jpg';
import caru2 from '../../ImgFile/products-2.jpg';
import caru4 from '../../ImgFile/akso9-01.jpg';
import caru5 from '../../ImgFile/cartige-10.jpg';
import caru6 from '../../ImgFile/akso9-04.jpg';
import caru7 from '../../ImgFile/products-3.jpg';
import { Link } from 'react-router-dom';
import '../hove.css';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';


const Home = () => {
    return (


        <div>
            <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid grid-cols-1'>
                <div className="card w-100 bg-base-100 shadow-xl  relative p-2 overflow-hidden">
                    <Link to='/akso'>
                        <img src={heroPic1} alt="aksosupavape" />


                        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 text-white p-4 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <div className='flex'>
                                <h1 className="text-center text-4xl font-bold">Akso Supa Nitro</h1>
                                {/* <p className="text-center">Grab it fast and get Discount</p> */}

                            </div>
                        </div>
                    </Link>

                </div>
                <div className="card w-100 bg-base-100 shadow-xl  p-2 overflow-hidden">
                    <Link to="/akso">
                        <img src={heroPic2} alt="Shoes" />
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 text-white p-4 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <div className='flex'>
                                <h1 className="text-center text-4xl font-bold">Akso Supa</h1>
                                {/* <p className="text-center">Now Available in all Flavor.</p> */}

                            </div>                        </div>

                    </Link>

                </div>
                <div className="card w-100 bg-base-100 shadow-xl p-2 overflow-hidden">
                    <Link to="/voltbar">
                        <img src={heroPic3} alt="Voltbar Vape 12000 puffs" />
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 text-white p-4 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <div className='flex'>
                                <h1 className="text-center text-4xl font-bold">Voltbar 12000 Puff's</h1>
                                <p className="text-center">Now Available in all Flavor.</p>

                            </div>
                        </div>

                    </Link>

                </div>
                <div className="card w-100 bg-base-100 shadow-xl p-2 overflow-hidden">
                    <Link to="/voltbarCartridge">
                        <img src={heroPic4} alt="Voltbar cartridge Vape 12000 puffs" />
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 text-white p-4 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <div className='flex'>
                                <h1 className="text-center text-4xl font-bold">Voltbar 12000 Puff's Cartridge</h1>
                                <p className="text-center">Now Available in all Flavor.</p>

                            </div>

                        </div>
                    </Link>

                </div>
            </div>
            <div>
                <h1 className='text-center text-4xl font-bold p-6'>
                    Top Products    </h1>
            </div>
            <div>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    freeMode={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru1} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru2} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru3} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru4} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru5} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru6} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={caru7} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={heroPic4} alt='vape'></img></Link></SwiperSlide>
                    <SwiperSlide><Link to="/items"><img className='hove' src={heroPic3} alt='vape'></img></Link></SwiperSlide>
                </Swiper>
            </div>

            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={VapCig} className="max-w-sm rounded-lg shadow-2xl" />
                    <div>
                        <h1 className="text-5xl font-bold">Benefits</h1>
                        <p className="py-6">Here are some potential benefits or positive aspects associated with vaping</p>
                        <li className='btn btn-primary'><Link to='/blogs'>Read</Link></li>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;