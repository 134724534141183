import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "IzaSyAolLCcWSCslvzQ5ofGf3p7r_wydchuxQU",
    authDomain: "houseofvapebd.firebaseapp.com",
    projectId: "houseofvapebd",
    storageBucket: "houseofvapebd.appspot.com",
    messagingSenderId: "121683266320",
    appId: "1:121683266320:web:9c9600f54b0633ead3b395",
    measurementId: "G-JHL7YFVXGY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export default app;