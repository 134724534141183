import React from 'react';
import Shops from './Shops';
import flover from '../../ImgFile/Cartridge_flover.jpg';
import cartige1 from '../../ImgFile/cartige-1.jpg';
import cartige2 from '../../ImgFile/cartige-2.jpg';
import cartige3 from '../../ImgFile/cartige-3.jpg';
import cartige4 from '../../ImgFile/cartige-4.jpg';
import cartige5 from '../../ImgFile/cartige-5.jpg';
import cartige6 from '../../ImgFile/cartige-6.jpg';
import cartige7 from '../../ImgFile/cartige-7.jpg';
import cartige8 from '../../ImgFile/cartige-8.jpg';
import cartige9 from '../../ImgFile/cartige-9.jpg';
import cartige10 from '../../ImgFile/cartige-10.jpg';


const VoltbarCartr = () => {
    return (
        <div>
            <Shops></Shops>
            <div>
                <img src={flover} alt=''></img>
            </div>
            <div className='lg:grid-cols-3 md:grid-cols-2 sm:grid grid-cols-1 m-4 p-4'>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige1} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige2} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige3} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige4} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige5} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige6} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div><div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige7} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige8} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige9} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={cartige10} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoltbarCartr;