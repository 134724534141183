import React from 'react';

const ItemsCard = ({ item, items, setItems }) => {
    const { name, quantity, supplier, taste, category, description, photo } = item;

    return (

        <div>
            <div className="card w-96 bg-base-100 shadow-xl">
                <figure><img className='hover' src={photo} alt="Vape" /></figure>
                <div className="card-body">
                    <h2 className="card-title">
                        {name}
                        <div className="badge badge-secondary">NEW</div>
                    </h2>
                    <p>{quantity} </p>
                    <p>{supplier} </p>

                    <div className="card-actions justify-end">
                        <div className="badge badge-outline">{category} </div>
                        <div className="badge badge-outline">{taste}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemsCard;