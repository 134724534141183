import React from 'react';
import AddItems from './AddItems';

const DashBoard = () => {
    return (
        <div className='p-32'>
            <AddItems></AddItems>
        </div>
    );
};

export default DashBoard;