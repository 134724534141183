import React from 'react';
import shopeHeader from '../../ImgFile/VOLTBAR-SWITCH-HOMEPAGE-BANNER-WEBSITE-1.png';

import products1 from '../../ImgFile/products-1.jpg';
import products2 from '../../ImgFile/products-2.jpg';
import products3 from '../../ImgFile/products-3.jpg';

import products7 from '../../ImgFile/vol-1.jpg';
import Shops from './Shops';

const Voltbar = () => {
    return (
        <div>
            <Shops></Shops>
            <div>
                <img src={shopeHeader} alt=''></img>
            </div>
            <div className='lg:grid-cols-3 md:grid-cols-2 sm:grid grid-cols-1 m-4 p-4'>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products1} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products2} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products3} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products7} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products2} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products3} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div><div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products1} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products2} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src={products3} alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">
                            VOLTBAR!
                            <div className="badge badge-secondary">NEW</div>
                        </h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-outline">Fashion</div>
                            <div className="badge badge-outline">Products</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Voltbar;