import React from 'react';
import Shops from './Shops';
import akso from '../../ImgFile/akso-Cover.jpg';

const Akso = () => {
    return (
        <div>
            <Shops></Shops>
            <div>
                <img className='w-full' src={akso} alt=''></img>
            </div>

        </div>
    );
};

export default Akso;